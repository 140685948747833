$(document).ready(function () {

    // $('.owl-carousel-galerija').owlCarousel({
    //     margin: 10,
    //     autoHeight: true,
    //     responsive: {
    //         0: {
    //             items: 1
    //         },
    //         600: {
    //             items: 1
    //         },
    //         1000: {
    //             items: 1
    //         }
    //     }
    // });



    let owlTwo = $('.owl-carousel-two');

    let dynamicGalleryItems;
    const ex1 = document.getElementById('ex1');
    const ex2 = document.getElementById('ex2');

        
    if (ex1) {
        ex1.addEventListener('click', function () {
            console.log('ckick');
            var galleryImageSource = $('#ex1 .image-product').attr('src');
            const lguid = ex1.getAttribute('lg-uid');

            if (lguid) {
                window.lgData[lguid].destroy(true)
            }
            const indexGalleryImage = [...dynamicGalleryItems].filter(img => img.src == galleryImageSource)[0]?.index;

            // console.log('on click indexGalleryImage', indexGalleryImage);
            // console.log('galerija', [...dynamicGalleryItems]);
            lightGallery(ex1, {
                dynamic: true,
                dynamicEl: [...dynamicGalleryItems], 
                index: indexGalleryImage
            })

        });
    }

    if (ex2) {
        ex2.addEventListener('click', function () {
            console.log('ckick');
            var galleryImageSource = $('#ex1 .image-product').attr('src');
            const lguid = ex2.getAttribute('lg-uid');

            if (lguid) {
                window.lgData[lguid].destroy(true)
            }
            const indexGalleryImage = [...dynamicGalleryItems].filter(img => img.src == galleryImageSource)[0]?.index;

            // console.log('on click indexGalleryImage', indexGalleryImage);
            // console.log('galerija', [...dynamicGalleryItems]);
            lightGallery(ex2, {
                dynamic: true,
                dynamicEl: [...dynamicGalleryItems], 
                index: indexGalleryImage
            })

        });
    }


    setTimeout(function () {
        if (!$("input[name='boja']:checked").val()) {
            $('.colors').find('input[name=boja]:eq(0)').attr('checked', 'checked');
        }
        $("input[type='radio'][name='boja']:checked").trigger('change');
    }, 0.1);

    var choosen_color = null;
    var choosen_size = null;
    var product = null;
    var buy_id = null;

    
    var productdata = $('.json').length ? JSON.parse($('.json').text()) : null;


    $("input[type='radio'][name='boja']").on('change', function () {
        if(!productdata) {
            return;
        }
        product = productdata.products[$(this).val()];
        product = product[Object.keys(product)[0]][0];

        choosen_color = $(this).val();
        choosen_size = null;
        setproductdata(product, choosen_color, choosen_size, "color");

        // set color hidden input
        $('#color').val(choosen_color);
        // load images for preview
        if (product.images.length < 4) {
            $('.thumbnails').thumbnailsGal();
        } else {
            owlTwo.thumbnailsGal({
                renderAfter: true,
                selector: '.owl-carousel-two'
            });
        }
        $('.zoom').zoom();

        $('.share-icons .share').each(function(index, value){
            value.dataset.url = window.location.href;
        });

        var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?color=' + choosen_color;
        window.history.pushState({ path: newurl }, '', newurl);

        //end load images for preview

        return false;
    });


    function initDataForColor() {
        if(!productdata){
            return;
        }
        // console.log($('input[name="boja"]:checked').val());
        product = productdata.products[$('input[name="boja"]:eq(0)').val()];
        // console.log('Product', productdata.products);
        if (product) {
            product = product[Object.keys(product)[0]][0];
        }

        choosen_color = $('input[name="boja"]:checked').val();
        // console.log(choosen_color);
        choosen_size = null;

        setproductdata(product, choosen_color, choosen_size, "color");


        return false;
    }

    function setImg() {
        // load images for preview
        $('.product-img-list').thumbnailsGal();
        $('.zoom').zoom();
        // console.log('treba da radi aman');
        //end load images for preview
    }

    function mappingGalleryImages() {
        const listGalleryOwl = $.makeArray($('.owl-carousel-two .owl-item'));

        dynamicGalleryItems = listGalleryOwl.map((item, index) => {
            const srcLight = $(item).find('img').attr('src');
            const thumbLight = $(item).find('img').attr('src');

            return {
                src: srcLight,
                thumb: thumbLight,
                // subHtml: item.title,
                index: index
            }
        })  
    }

    function setproductdata(product, choosen_color = null, choosen_size = null, type = null) {

        if (type != "first") {
            $(".thumbnails").text('');

            $('.owl-carousel-two .item').each((index, value) => {
                owlTwo.trigger('remove.owl.carousel', index);
            })
            if(product.images && product.images.length) {
                $.each(product.images, function (key, value) {
                    if (key == 0) {
                        $('.image-product').attr('src', value);
                    }
                    item = '<a href="' + value + '" class="product-margin"> <img src="' + value + '" alt="Thumbnail" class="thumb"> </a>';

                    owlTwo.owlCarousel('add', '<div class="item"> ' + item + ' </div>');

                });
            } 
            owlTwo.owlCarousel('update'); //Updatuje carousel
            owlTwo.thumbnailsGal({
                renderAfter: true,
                selector: '.owl-carousel-two'
            });

            // console.log('zamena boje');

            mappingGalleryImages();
            
               

        }

       

        if (type != "first") {
            $(".thumbnails").text('');
            let owl = $('.owl-carousel-galerija');

            $('.owl-carousel-galerija .item').each((index, value) => {
                owl.trigger('remove.owl.carousel', index);
            })

            $.each(product.images, function (key, value) {
                if (key == 0) {
                    $('.image-product').attr('src', value);
                }
                item = '<span "><img src="' + value + '" alt="MODAL" ></zoom>';

                owl.owlCarousel('add', '<div class="item"> ' + item + ' </div>');

            });
            owl.owlCarousel('update'); //Updatuje carousel
            owl.thumbnailsGal({
                renderAfter: true,
                selector: '.owl-carousel-galerija'
            });
        }


        //sizes
        //var sizes = Object.keys(eval('productdata.products["' + choosen_color + '"]')).filter(function(val) { return val!='' });
        //console.log();



        if (type != "size") {

            //$('.velicine').text('');
            $('.tabela-podaci tbody').text('');
            $('#accordion').text('');
            $('.tabela-podaci-h').show();
            let collapse_index = 1;
            let show = 'show';
            $.each(productdata.products[choosen_color], function (key, value) {
                let tdList;
                
                let priceValue = Number(Math.fround(value[0].price )).toFixed(4);
                let posaljiNaUpit = $('.upit-text').text();

                console.log(posaljiNaUpit);

                let zaglavlje_tabele = '<th class="d-xs-none">'+ code +'</th><th>' + stock + '</th><th>' + arrival + '</th><th>'+ price +'</th><th>'+ quantity +'</th>';
                let sifra_velicine = value[0].real_id;
                tdList += '<td class="size d-xs-none">' + value[0].real_id + '</td>';
                tdList += '<td class="quantity quan' + key + '">' + value[0].quantity + '</td>';
                tdList += '<td class="arrivals arriv' + key + '">' + value[0].arrivals + '</td>';
                if(priceValue == 0) {
                    tdList += '<td class="price">' + posaljiNaUpit + '</td>';

                } else {
                    tdList += '<td class="price">' + Number(priceValue) + '&nbsp;EUR</td>';
                }

                if (value[0].incart) {
                    if ($(window).width() < 768) {
                        tdList += '<td  colspan="3">'+ productListStatus +'</td>';
                    } else {
                        tdList += '<td class="in-list">'+ productListStatus +'</td>';
                        $('.dugme-za-upit').attr("disabled", "disabled");
                    }
                } else {
                    if ($(window).width() < 768) {
                        $('.kolicina').remove();
                        tdList += '<td class="quantity-buy">' +
                            '<div class="input-counter">' +
                            '<i class="icon-remove minus remove-package"></i>' +
                            '<input name="' + value[0].real_id + '" class=" form-control unesi-kolicinu" type="number" min="0" value="0"  >' +
                            '<i class="icon-add plus add-package"></i>' +
                            '</div>' +
                            '</td>';
                        $('.dugme-za-upit').removeAttr("disabled");


                    } else {
                        tdList += '<td class="quantity-buy quan-buy' + key + '">' +
                            '<div class="input-counter">' +
                            '<i class="icon-remove minus remove-package"></i>' +
                            '<input name="' + value[0].real_id + '" class=" form-control unesi-kolicinu" type="number" min="0" value="0"  >' +
                            '<i class="icon-add plus add-package"></i>' +
                            '</div>' +
                            '</td>';
                        $('.dugme-za-upit').removeAttr("disabled");
                    }





                }
                $('.tabela-podaci tbody').append('<tr>' + tdList + '</tr>');
                let tableDIV = '<table class="w-100">' +
                    '<tbody class="d-flex flex-row justify-content-between" style="height: 100px;">' +
                    '<tr class="d-flex flex-column justify-content-around">' + zaglavlje_tabele + '</tr><tr class="d-flex flex-column pl-3">' + tdList + '</tr></tbody></table>';


                if (collapse_index != 1) {
                    show = '';
                }

                const parsetTableHTML = $(tableDIV)[1].outerHTML;
                $('#accordion').append('<div class="card mb-2">' +
                    '<div class="accordion-heading" id="heading' + collapse_index + '">' +
                    '<a class="btn btn-link" data-toggle="collapse" data-target="#collapse' + collapse_index + '" aria-expanded="true" aria-controls="collapse' + collapse_index + '">' + sifra_velicine + ' </a>' +
                    '</div>' +
                    '<div id="collapse' + collapse_index + '" class="collapse ' + show + '" aria-labelledby="heading' + collapse_index + '" data-parent="#accordion">' +
                    '<div class="card-body">' +
                    parsetTableHTML +
                    '</div></div></div>');

                collapse_index++;

                let color_table_head = $('.drop-size .show').parent();
                color_table_head.find('.accordion-heading').addClass('main-color-bg');
                // console.log('Oboji',color_table_head.find('.accordion-heading'));
                $('.drop-size .btn-link').on('click', function () {
                    $('.accordion-heading').removeClass('main-color-bg');
                    $(this).parent().toggleClass('main-color-bg');
                });

                // Kolicina 0 Start
                let product_cat = $('.product-cat-info').text();
                let kolicina = parseInt($('.quan' + key).text()) + parseInt($('.arriv' + key).text());
                let quantInput = $('.quan-buy' + key + ' input');

                // set max quantity
                quantInput.attr('max', kolicina);

                if (kolicina == 0 && product_cat != "Kalendari" && product_cat != "Kožna galanterija") {
                    $('.quantity-buy').append('Nije dostupan');
                    $('.unesi-kolicinu').addClass('d-none');
                    $('.input-counter').addClass('d-none');
                }
                // Kolicina 0 End
                if (value[0].quantity_query_on_request == 1 && (product_cat == 'Kalendari' || product_cat == 'Kožna galanterija')) {
                    // console.log(value[0].quantity_query_on_request);
                    // console.log("Koza")
                    $('.table .quantity').empty().append('Na upit');
                    $('.table .arrivals').empty().append('Na upit');
                    $('.card-body .quantity').empty().append('Na upit');
                    $('.card-body .arrivals').empty().append('Na upit');

                    quantInput.attr('max', 999999);
                }


                /*$('<a/>', {
                    href: '#',
                    class: 'btn btn-default size',
                    text: value
                }).appendTo('.velicine');
                <tr>
                            <td class="size">50.030.90-S</td>
                            <td>700</td>
                            <td>3.50 EUR</td>
                            <td>
                                <input class="unesi-kolicinu" type="number" min="0" value="0">
                            </td>
                        </tr>
                */
            });
        }

        $('.minus').click(function () {
            // TOTAL PACK
            var $input = $(this).parent().find('input');
            let packageMin = $('#commercial-package').val() ? +$('#commercial-package').val() : 1;
            let totalPackageFiled = $('#total-package');
            let totalPackageFiledMob = $('#total-package-mob');
            let totalPackageSum = +totalPackageFiled.val();
            
            var count = parseInt($input.val()) - packageMin;

            if (count < 0) {
                count = 0
                 $('button.upit-submit').prop('disabled', true).css('cursor', 'not-allowed !important');
            } else {
                count = count;
                // $('button.upit-submit').prop('disabled', true).css('cursor', 'not-allowed !important');
            }
            $input.val(count);
            $input.change();
            return false;
        });
        $('.plus').click(function () {
            
            // TOTAL PACK
            var $input = $(this).parent().find('input');
            let packageMin = $('#commercial-package').val() ? +$('#commercial-package').val() : 1;
            let reqQuanty = +$(this).val();
            let totalPackageFiled = $(this);
            let totalPackageSum = 0;

            $input.val(parseInt($input.val()) + packageMin);
            $input.change();

            $('button.upit-submit').prop('disabled', false);
            if (reqQuanty % packageMin !== 0) {
                totalPackageSum = reqQuanty + (packageMin - (reqQuanty % packageMin));
            }else{
                totalPackageSum = reqQuanty;
            }
            
            totalPackageFiled.val(totalPackageSum);
        });
        let timeoutId = 0;
        $(".unesi-kolicinu").on("keypress", function () {
            
            clearTimeout(timeoutId); // doesn't matter if it's 0
            timeoutId = setTimeout(() => {
               
                // TOTAL PACK
                let packageMin = $('#commercial-package').val() ? +$('#commercial-package').val() : 1;
                let reqQuanty = +$(this).val();
                let totalPackageFiled = $(this);
                let totalPackageSum = 0;

                if (reqQuanty % packageMin !== 0) {
                    totalPackageSum = reqQuanty + (packageMin - (reqQuanty % packageMin));
                }else{
                    totalPackageSum = reqQuanty;
                }
                if (totalPackageSum > 0) {
                    $('button.upit-submit').prop('disabled', false);
                }else{
                    $('button.upit-submit').prop('disabled', true);
                }
                totalPackageFiled.val(totalPackageSum);
            }, 500);
            // return false;
        });

        var quantityZero = $('.unesi-kolicinu').val();

        if (quantityZero == 0) {
            $('button.upit-submit').prop('disabled', true).css('cursor', 'not-allowed !important');
        } else {
            $('button.upit-submit').prop('disabled', false);
        }

    }


    initDataForColor(); 
    $('.list-pr').click(function (e) {
        e.preventDefault();
        let address = $(this).find('a').attr('href');
        // console.log(address);
        $('.image-product').attr('src', address);
    });

});
