$('.owl-carousel-print-process').owlCarousel({
    autoplay:true,
    autoplayTimeout:4000,
    autoplayHoverPause:true,
    nav:false,
    pagination: false,
    dots: false,
    margin:20,
    stopOnHover:true,
    loop:true,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:2
        },
        1000:{
            items:2
        },
        1600:{
            items:2
        }
}
});