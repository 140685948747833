window.onbeforeunload = function () {
    if (
        $("#inputName").val() != "" ||
        $("#inputEmail").val() != "" ||
        $("#inputPhone").val() != ""
    ) {
        localStorage.setItem("name", $("#inputName").val());
        localStorage.setItem("email", $("#inputEmail").val());
        localStorage.setItem("phone", $("#inputPhone").val());
    }
};

window.onload = function () {
    if ($("#inputName").val() == "") {
        var name = localStorage.getItem("name");
        var email = localStorage.getItem("email");
        var phone = localStorage.getItem("phone");

        if (name == "undefined") {
            name = "";
            email = "";
            phone = "";
        }

        if (name != null) $("#inputName").val(name);
        if (phone != null) $("#inputPhone").val(phone);
        if (email != null) $("#inputEmail").val(email);
    }
};

$("#posalji_upit").click(function () {
    const tableRows = $(".upiti-row .red-proizvod");
    tableRows.each(function (index, value) {
        const quantityValue = parseInt($(value).data("lager"));
        const arrivalsValue = parseInt($(value).data("carina"));
        const categoryName = $(value).data("kategorija");
        const nameValue = $(value).data("ime");
        const inputValue = parseInt($(value).find(".unesi-kolicinu").val());
        let zbir = quantityValue;

        if (inputValue > zbir) {

            $("#vecaKolicina").modal("show");

            $(".modal-content .modal-body .modal-products").append(
                "<span>" +
                    nameValue +
                    " (Na lageru: " +
                    quantityValue +
                    ")</span> <br>"
            );
            return;
        }
    });
});

$(".empty-modal").click(function () {
    $(".modal-content .modal-body .modal-products").empty();
});

document.addEventListener(
    "invalid",
    (function () {
        return function (e) {
            e.preventDefault();
            if (document.getElementById("removePopup")) {
                document.getElementById("removePopup").focus();
            }
        };
    })(),
    true
);
