
    $(".filterss").on("click", ".collapse-link", function(){
        // $(".up-down-arrow").toggleClass("down-arrow");
        $(".up-down-arrow",this).toggleClass("down-arrow");
        
      });

      $(document).ready(function() {

        $(document.body).on('change', "input[type='radio'][name='subcategory'], input[type='radio'][name='category']", function(e) {
            
          var params = "?" + $("#filter :input[value!='']").filter(function(index, element) {
              return $(element).val() != '';
          }).serialize();
          
          $.get("getfilters?" + params, function(data) {
                $(".filterss").html(data);
            });
        });
        $(document.body).on('change', '#filter', function(e) {

            
            setAdditionalFilterName(e.target);
            
            var search = new window.URLSearchParams(window.location.search);
            search = search.get('search') ? search.get('search') : "";
            var params = "?"+ "search="+ search + "&" + $("#filter :input[value!='']").filter(function(index, element) {
              return $(element).val() != '';
          }).serialize();
           
            $.get(params + "&ajax=1", function(data) {
                $(".products").html(data);
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
                window.history.pushState("", "", params);

            });

            //this.submit();
        });
    
        
    });

    function setAdditionalFilterName(el)
    {
        const name = $(el).attr('name');
        const target = $(el).closest('.collapse').find("input[name='tn-"+ name +"']");
     
        target.val($(el).data('name'));
    } 
    
