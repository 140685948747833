$(".tabela-podaci-h .upit-submit").click(function () {
  let lager = $(".quantity").text();
  let carina = $(".arrivals").text();
  let dodavanje = parseInt($(".unesi-kolicinu").val(), 10);
  let zbir = parseInt(lager);
  if (zbir < dodavanje) {
    $("#vecaKolicina").modal("show");
  }
});
 

document.addEventListener(
  "invalid",
  (function () {
    return function (e) {
      e.preventDefault();
      // console.log(document.getElementById("removePopup"));
      // if(document.getElementById("removePopup") != 'undefined') {
        // document.getElementById("removePopup").focus(); 
      // }
    };
  })(),
  true
); 

$("#accordion").collapse({
  toggle: false,
});

$(".tabela-podaci-h .upit-submit").click(function () {
  const tableRows = $(".tabela-podaci-h tbody tr");

  check = true;
  tableRows.each(function (index, value) {
    const quantityValue = parseInt($(value).find(".quantity").text());
    const arrivalsValue = parseInt($(value).find(".arrivals").text());
    const inputValue = parseInt($(value).find(".unesi-kolicinu").val());
    const zbir = quantityValue;

    if (inputValue > zbir) {
      console.log(zbir, inputValue);
      $("#vecaKolicina").modal("show");
      check = false;
      return;
    }
  });

  $("form").submit(function () {
    if (check == false) {
      return false;
    }
  });
});
