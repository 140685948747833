  
$('.search_product_mobile').click(function() {
    $('.mobile-search').toggleClass('mobile-search-show');
});

//#region  Korpa mobile ***********************

$('.cart_button').click(function() {
    $('.cart-menu').addClass('cart-right-open');
});


$('.cartBackBtn').click(function() {
    $('.cart-menu').removeClass('cart-right-open');

});

$("body").click(function(e) {
    $('.cart-menu').removeClass('cart-right-open');
});

$(".cart_button").click(function(e) {
    e.stopPropagation(); // this stops the event from bubbling up to the body
});
//#endregion