$(".collapse-container").on("click", ".collapse-link", function(){
    console.log($(this).find('.strelica-home').hasClass('down-arrow'));
    if($(this).find('.strelica-home').hasClass('down-arrow')){
        $('.strelica-home').removeClass("down-arrow");
        // console.log($(this).find('.up-down-arrow').removeClass("down-arrow"));
    }else{
        $('.strelica-home').removeClass("down-arrow");
        $(this).find('.strelica-home').addClass("down-arrow");
        
    }
    
});